import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Icon,Slider } from 'libs';

class SliderJust extends Component {
    constructor (props) {
        super(props);
        this.state = {

        };
    }
    // 处理-
    handleReduce () {
        let {
            data,
            type,
            updateData,
            channel,
            min
        } = this.props;
        let num = this.props.value;
        --num;
        data[channel][type] = num < min ? min : num;
        updateData(data);
    }
    // 处理+
    handleAdd () {
        let {
            data,
            type,
            updateData,
            channel,
            max
        } = this.props;
        let num = this.props.value;
        ++num;
        data[channel][type] = num > max ? max : num;
        updateData(data);
    }
    // 处理滑动条滑动值
    handleSliderChange (val) {
        let {
            data,
            type,
            updateData,
            channel
        } = this.props;
        data[channel][type] = val;
        updateData(data);
    }
    render () {
        let {
            value,
            mode,
            max,
            min
        } = this.props;
        return (
            <Row className='slider-wrapper'>
                <Col span={24} className='list'>
                    <Col className='point' span={2} onClick={this.handleReduce.bind(this)}>
                        <Icon type='_' />
                    </Col>
                    <Col span={18} className='slide'>
                        {
                            mode === 'mark' ? <Slider
                                value={value}
                                max={max}
                                min={min}
                                marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
                                onChange={this.handleSliderChange.bind(this)}
                            /> : <Slider
                                value={value}
                                max={max}
                                min={min}
                                onChange={this.handleSliderChange.bind(this)}
                            />
                        }
                    </Col>
                    <Col className='point' span={2} onClick={this.handleAdd.bind(this)}>
                        <Icon type='+' />
                    </Col>
                    <Col span={2}>{value}</Col>
                </Col>
            </Row>
        );
    }
}

export default SliderJust;
